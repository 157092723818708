<template>
  <div class="UserInvite column">
    <!-- <base-input
      v-model.trim="email"
      @target="emailValidationState"
      :alert="true"
      :alert-text="'invalid email'"
      :label="'Email'"
      :placeholder="'Enter email'"
      :type="'email'"
      :validation-function="() => emailValidityState"
    /> -->

    <div class="UserInvite__password-wrapper row">
      <!-- <base-dropdown-input
        @selected="passwordType = $event"
        :default="true"
        :label="'Temporary password'"
        :options="['Auto', 'Custom']"
        :placeholder="'Select a type'"
      /> -->
      <!-- <base-input
        v-if="passwordType === 'custom'"
        v-model.trim="customPassword"
        @input="customPasswordValidationState"
        :alert="true"
        :alert-text="'Invalid password'"
        :label="'Temporary Password'"
        :placeholder="'Enter custom password'"
        :type="'password'"
        :validation-function="() => customPasswordValidityState"
      /> -->
    </div>

    <!-- <base-dropdown-input
      @selected="setSendEmail"
      :default="true"
      :label="'Send Email Notification'"
      :options="['Yes', 'No']"
      :placeholder="'Select an option'"
    />

    <base-dropdown-input
      @selected="userType = $event"
      :default="true"
      :label="'Select Type of User'"
      :options="['Client', 'Employee']"
      :placeholder="'Select an option'"
    /> -->

    <base-button
      :action="inputChecks"
      :button-style="'primary'"
      :elevated="true"
      :on-enter="true"
      :text="'Invite User'"
    />
  </div>
</template>

<script>
// Packages
// import {
//   AuthenticationDetails,
//   CognitoUser,
//   CognitoUserAttribute,
//   CognitoUserPool,
// } from 'amazon-cognito-identity-js'
// Components
import BaseButton from '../../components/base-button.vue'
// import BaseDropdownInput from '../../components/base-dropdown-input.vue'
// Mixins
import {
  ValidationMixin
} from '../../utils/validation-mixin'

export default {
  name: 'UserInvite',

  components: {
    BaseButton,
    // BaseDropdownInput,
  },

  mixins: [ValidationMixin],

  mounted () {
    if (typeof window === 'object') {
      window.addEventListener('keyup', this.enterBtnEventListener)
    }
  },

  beforeDestroy() {
    if (typeof window === 'object') {
      window.removeEventListener('keyup', this.enterBtnEventListener)
    }
  },

  data () {
    return {
      customPassword: '',
      customPasswordValidityState: false,
      email: '',
      emailValidityState: false,
      passwordType: '',
      sendEmail: false,
      userType: '',
    }
  },

  methods: {
    customPasswordValidationState () {
      // No reason to check the rest if it isn't ...
      if (!(this.customPassword.length >= 8)) {
        this.passwordValidationState = false
        return
      }

      if (this.isValidPassword(this.customPassword)) {
        this.customPasswordValidityState = true
        return
      }
      this.customPasswordValidityState = false
    },

    emailValidationState (e) {
      this.emailValidityState = e.validity.valid
    },

    enterBtnEventListener (e) {
      if (e.keyCode === 13) {
        this.inputChecks()
      }
    },

    inputChecks () {
      // Initial Checks
      if (!this.emailValidityState) {
        this.alertError('Email is invalid. Must contain a "@domain.xyz"', 'Input validation')
        return
      }
      // If custom password selected && invalid password
      if (this.passwordType === 'Custom' && !this.customPasswordValidityState) {
        this.alertError('Temporary password is not valid. Must contain 8 or more characters', 'Input validation')
        return
      }

      // Construct payload
      const payload = {
        email: this.email,
        tempPassword: this.customPassword,
        sendEmail: this.sendEmail,
      }
      this.inviteUser(payload)
    },

    inviteUser (payload) {
      this.CLog(payload)
      // If userType === 'client', call createClient API
      // If userType === 'employee', call createEmployee API
    },

    setSendEmail (type) {
      if (type.toLowerCase() === 'yes') {
        this.sendEmail = true
        return
      }
      this.sendEmail = false
    },
  },
}
</script>

<style lang="sass">

</style>