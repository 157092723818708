var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserInvite column" },
    [
      _c("div", { staticClass: "UserInvite__password-wrapper row" }),
      _c("base-button", {
        attrs: {
          action: _vm.inputChecks,
          "button-style": "primary",
          elevated: true,
          "on-enter": true,
          text: "Invite User"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }